import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { AuthGuard } from './services/authguard/auth.guard';
import { WorksourceCenterComponent } from './worksource-center/worksource-center.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('./about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import(
        './components/dashboard/employer-dashboard/employer-dashboard.module'
      ).then((m) => m.EmployerDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import(
        './components/dashboard/job-seeker-dashboard/job-seeker-dashboard.module'
      ).then((m) => m.JobSeekerDashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'worksource-center',
    component: WorksourceCenterComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./user-listing/user-listing.module').then(
        (m) => m.UserListingModule
      ),
    canActivate: [AuthGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'job-search',
    loadChildren: () =>
      import('src/app/job-section/job-search.module').then(
        (m) => m.JobSearchModule
      ),
  },
  {
    path: 'cbo',
    loadChildren: () =>
      import(
        './features/community-based-organization/community-based-organization.module'
      ).then((m) => m.CommunityBasedOrganizationModule),
  },
  {
    path: 'staff',
    loadChildren: () =>
      import('./features/lawa-staff/lawa-staff.module').then(
        (m) => m.LawaStaffModule
      ),
  },
  { path: '**', redirectTo: '' }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
