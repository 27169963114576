<!-- <ng-container *ngIf="!loginDisplay">
  <h1 id="tableLabel">Weather forecast</h1>

  <p>This component demonstrates fetching data from the server.</p>

  <p *ngIf="!forecasts">
    <em
      >Loading... Please refresh once the ASP.NET backend has started. See
      <a href="https://aka.ms/jspsintegrationangular"
        >https://aka.ms/jspsintegrationangular</a
      >
      for more details.</em
    >
  </p>
  <table *ngIf="forecasts">
    <thead>
      <tr>
        <th>Date</th>
        <th>Temp. (C)</th>
        <th>Temp. (F)</th>
        <th>Summary</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let forecast of forecasts">
        <td>{{ forecast.date | date : "fullDate" }}</td>
        <td>{{ forecast.temperatureC }}</td>
        <td>{{ forecast.temperatureF }}</td>
        <td>{{ forecast.summary }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
<ng-container *ngIf="loginDisplay">
  <button *ngIf="loginDisplay" (click)="logout()">Logout</button>
</ng-container>
<ng-container>
  <router-outlet *ngIf="!isIframe"></router-outlet>
</ng-container>
<ng-container *ngIf="!loginDisplay">
  <button (click)="loginAzureB2C()">Azure B2c Login</button>
</ng-container>

<ng-container *ngIf="!loginDisplay">
  <p>Angular single-page application built with MSAL Angular</p>
  <p>Sign-in with Azure AD B2C and get an ID Token</p>
  <p>
    This sample demonstrates how to configure MSAL Angular to sign-in, sign-out
    and protect a route.
  </p>
</ng-container>
<br />

<ng-container *ngIf="loginDisplay" id="table-container">
  <ng-container>
    <div>
      <h2>Welcome to {{ firstName }} {{ lastName }}</h2>
    </div>
    <ng-container *ngIf="dataSource !== null && dataSource.length > 0">
      <table>
        <thead>
          <tr>
            <th>Claim</th>
            <th>Value</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody class="table">
          <tr *ngFor="let item of dataSource">
            <td>{{ item.claim }}</td>
            <td>{{ item.value }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </ng-container>
</ng-container>
<footer *ngIf="loginDisplay">
  <div class="footer-text">
    How did we do?
    <a
      href="https://forms.office.com/Pages/ResponsePage.aspx?id=v4j5cvGGr0GRqy180BHbR73pcsbpbxNJuZCMKN0lURpUOU5PNlM4MzRRV0lETkk2ODBPT0NBTEY5MCQlQCN0PWcu"
      target="_blank"
    >
      Share your experience with us!</a
    >
  </div>
</footer> -->
<div class="container-fluid p-0">
<app-nav-bar (loginClicked)="login()" [CommonContent]="commonContent"></app-nav-bar>
<router-outlet />
<app-footer [CommonContent]="commonContent"></app-footer>
</div>