<div class="footer-banner">
  <div class="px-5 py-5 pb-3">
    <div class="row">
      <div class="col-md-3">
        <h5 class="footer-title pb-2"> {{
          (CommonContent && CommonContent.businessJobHeading) ?? "Business & Jobs"}}</h5>
        <ul class="footer-item list-unstyled">
          <li>  {{(CommonContent && CommonContent.businessAddress) ?? "6053 W. Century Blvd., Suite 300 Los Angeles, CA 90045"}}</li>
          <li class=""><img src="../../../../assets/icons/fi_phone.svg" /> {{
          (CommonContent && CommonContent.businessPhone) ?? "(424) 646-7300"}}</li>
          <li>
            <a href="mailto:{{ CommonContent && CommonContent.businessEmail ? CommonContent.businessEmail : 'businessandjobs@lawa.org' }}">
              <img src="../../../../assets/icons/fi_mail.svg" alt="Email Icon" />
              {{ CommonContent && CommonContent.businessEmail ? CommonContent.businessEmail : 'businessandjobs@lawa.org' }}
            </a>
          </li>
          <li class=""> <img src="../../../../assets/icons/fi_clock.svg" /> 
          {{(CommonContent && CommonContent.businessTime) ?? "Monday - Friday 8am-6pm"}}</li>
        </ul>
      </div>
      <div class="col-md-3">
        <h5 class="footer-title pb-2">Support</h5>
        <ul class="footer-item list-unstyled">
          <li><a href="{{ CommonContent && CommonContent.cityOfLa }}">City of LA</a></li>
          <li><a href="{{ CommonContent && CommonContent.accessibility }}">Accessibility</a></li>
          <li><a href="{{ CommonContent && CommonContent.commentsContactUs }}">Comments/Contact Us</a></li>
        </ul>
      </div>
      <div class="col-md-3">
        <h5 class="footer-title pb-2">Legal & Information</h5>
        <ul class="footer-item list-unstyled">
          <li><a href="{{ CommonContent && CommonContent.disclaimer }}">Disclaimer</a></li>
          <li><a href="{{ CommonContent && CommonContent.privacyPolicy }}">Privacy Policy</a></li>
          <li><a href="{{ CommonContent && CommonContent.sitemap }}">Sitemap</a></li>
        </ul>
      </div>
      <div class="col-md-3">
        <h5 class="footer-title pb-2">{{(CommonContent && CommonContent.getInTouchHeading) ?? "Get In Touch"}}</h5>
        <ul class="footer-item list-unstyled">
          <li class="white-space-prewrap" *ngIf="(CommonContent && CommonContent.getInTouchAddress)">
            <span [innerHTML]="CommonContent.getInTouchAddress"></span>
          </li>
          <li *ngIf="!(CommonContent && CommonContent.getInTouchAddress)">
            <span>
              Los Angeles International Airport, <br /> 1 World Way, Los Angeles, CA 90045
            </span>
          </li>
          <li>{{(CommonContent && CommonContent.getInTouchPhone) ?? "Ph: (855) 463-5252"}}</li>
          <li *ngIf="(CommonContent && CommonContent.getInTouchMessage)">
            <span [innerHTML]="CommonContent.getInTouchMessage"></span>
          </li>
          <li *ngIf="!(CommonContent && CommonContent.getInTouchMessage)">
            <span>
              For TTY, please call California Relay Service at (800) 735-2929
            </span>
          </li>
        </ul>
      </div>
      <div class="col-md-7">
        <h5 class="footer-title pb-2"> {{
          (CommonContent && CommonContent.fshpHeading) ?? "First Source Hiring Program"}} </h5>
        <p class="footer-p">{{
          (CommonContent && CommonContent.fshpText) ?? "The First Source Hiring Program (FSHP) is a workforce development program managed  by the Business, Jobs and Social Responsibility Division at Los Angeles World Airports.  FSHP connects job seekers in the  Los Angeles area to quality airport careers. The FSHP also connects job seekers with career readiness resources and  helps the companies doing business at LAWA find the talent needed to operate a world-class, gold standard airport."}}</p>
      </div>
      <div class="col-md-5">
        <div class="d-flex justify-content-start align-items-baseline flex-wrap">
          <span class="popular-job-heading pt-2">Popular Job Categories:</span>
          <ng-container *ngIf="CommonContent">
            <span *ngFor="let item of CommonContent.popularJobs" class="popular-jobs">{{item}}</span>
          </ng-container>

        </div>
      </div>
    </div>
    <hr class="text-white" />
    <div class="d-flex justify-content-between align-items-center">
      <p class="copyright-text mb-0">LAWA © 2024. All rights reserved.</p>
      <div class="d-flex gap-3">
        <p class="copyright-text mb-0">LAX</p>
        <p class="copyright-text mb-0">VNY</p>
      </div>
    </div>
  </div>

</div>
