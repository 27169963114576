import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  RedirectRequest,
  EventMessage,
  EventType,
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
} from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { createClaimsTable } from '@portal/utils';
import { b2cPolicies, SignUpRoles } from '@portal/configs';
import { AppConstants } from '../../appconstants';
import { AuthService } from 'src/app/services/authguard/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserInfoService } from 'src/app/services/userclaims/userinfo.service';
import { environment } from 'src/environments/environment';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { ProfileService } from 'src/app/services/profile.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string;
  tfp?: string;
};

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrl: './user-login.component.css',
})
export class UserLoginComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  public loginDisplay: boolean = false;
  activeAccount!: AccountInfo | null;
  @Output() loginClicked: EventEmitter<void> = new EventEmitter<void>();
  displayedColumns: string[] = ['claim', 'value', 'description'];
  dataSource: any = [];
  error: string = '';
  uname: any = '';
  loading: boolean = false;
  firstName: any = '';
  lastName: any = '';
  userRole: any = '';
  authenticated: boolean = false;
  previewUserImage: any = '../../../../assets/icons/user-profile.svg';

  constructor(
    private authService: MsalService,
    private authenticationService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private snackBar: MatSnackBar,
    private readonly auth: AuthService,
    private userInfoService: UserInfoService,
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.subscribeEvents();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.getClaims(
          this.authService.instance.getActiveAccount()?.idTokenClaims
        );
      });
  }

  checkUserStatus(account: AccountInfo): void {
    return; //need to check API
    this.authenticationService.checkUserStatus(account.username).subscribe(
      (status: string) => {
        if (status === 'disabled') {
          this.showToastMessage('Your account has been re-activated.');
        }
      },
      (error) => {
        console.error('Error checking user status:', error);
      }
    );
  }

  showToastMessage(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000, // Duration in milliseconds
    });
  }

  getClaims(claims: any) {
    if (claims) {
      const claimsTable = createClaimsTable(claims);
      this.dataSource = [...claimsTable];
    }
  }
  getAccessToken(): Observable<string> {
    const scopes = ['openid', 'profile']; // Add the scopes you want to request access to.
    return this.authService.initialize().pipe(
      tap((res: any) => {
        this.authService.acquireTokenSilent({ scopes }).pipe(
          tap((result: any) => {
            console.log('Access token acquired:', result.accessToken);
          }),
          catchError((error: any) => {
            console.log('Error acquiring access token:', error);
            return [];
          })
        );
      }),
      catchError((err: any) => {
        console.log('Error acquiring access token:', err);
        return [];
      })
    );
  }
  getUserInfo() {
    this.uname = this.authService.instance.getActiveAccount();
    if (this.uname) {
      this.firstName = this.uname.idTokenClaims.given_name;
      this.lastName = this.uname.idTokenClaims.family_name;
    } else {
      console.log('User not authenticated.');
    }
  }
  onLogin(): void {
    this.loginClicked.emit(); // Emit the event when the button is clicked
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.activeAccount = this.authService.instance.getActiveAccount();
      // console.log(this.activeAccount);
    }
  }

  logout() {
    localStorage.removeItem('Access-Token-Azure-B2C');
    localStorage.removeItem(AppConstants.USER);
    localStorage.setItem(AppConstants.LOGINSTATUS, AppConstants.FALSE);
    this.authService.logout();
    this.router.navigate(['/']);
  }

  viewProfile() {
    const user = localStorage.getItem(AppConstants.USER);
    if (!user) return;

    this.userRole = JSON.parse(user).role;

    let token: any = localStorage.getItem('Access-Token-Azure-B2C');
    let decodedJWT = JSON.parse(window.atob(token.split('.')[1]));

    const routeMap = {
      [AppConstants.JOBSEEKER]: AppConstants.JOBSEEKER_PROFILE_URL,
      [AppConstants.EMPLOYER]: AppConstants.EMPLOYER_PROFILE_URL,
    };

    const route = routeMap[this.userRole];
    if (route) {
      this.router.navigate([route]);
    }
  }

  private async loginToModule(): Promise<void> {
    let obj: any = localStorage?.getItem(AppConstants.USER);
    if (!obj) return;
    obj = JSON.parse(obj ?? '');
    let basicUserDetails: any;
    if (obj.role === '') {
      this.auth.getUerProfile(obj.email).subscribe((response: any) => {
        const userData = response.data;
        basicUserDetails = {
          userId: userData.userProfile.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          role: userData.role,
          email: obj.email,
        };
        // Store the basic user details in local storage
        localStorage.setItem(
          AppConstants.USER,
          JSON.stringify(basicUserDetails)
        );
        if (basicUserDetails.role === SignUpRoles.Employer) {
          this.router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
        } else if (basicUserDetails.role === SignUpRoles.JobSeeker) {
          this.router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
        } else {
          this.router.navigate(['/']);
        }
      });
    } else {
      await this.userInfoService
        .createProfile({
          email: obj.email,
          role: obj.role,
        })
        .subscribe((res) => {
          // if (res?.success) localStorage.setItem('UserId', res?.data);
          let obj1: any = localStorage?.getItem(AppConstants.USER);
          basicUserDetails = JSON.parse(obj1 ?? '');
          basicUserDetails.userId = res.data;
          localStorage.setItem(
            AppConstants.USER,
            JSON.stringify(basicUserDetails)
          );

          if (obj.role === SignUpRoles.Employer) {
            this.router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
          } else if (obj.role === SignUpRoles.JobSeeker) {
            this.router.navigate([AppConstants.JOBSEEKER_PROFILE_URL]);
          } else {
            this.router.navigate(['/']);
          }
        });
    }
  }

  private subscribeEvents() {
    this.profileService.profileUpdated
      .pipe(takeUntil(this._destroying$))
      .subscribe((value) => {
        if (value.profilePictureBase64) {
          this.previewUserImage = value.profilePictureBase64;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
