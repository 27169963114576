import { Component, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../../appconstants';
import { AuthService } from 'src/app/services/authguard/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { MsalService } from '@azure/msal-angular';
import { AppMonitorService } from 'src/app/services/app-monitor.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.css'
})
export class NavBarComponent {
  isSubmenuVisible = false;
  protected basePath = environment.umbracoBaseUri + "/";
  @Output() loginClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() CommonContent: any;
  userRole = '';
  jobSeeker = AppConstants.JOBSEEKER;
  employer = AppConstants.EMPLOYER;
  lawaStaff = AppConstants.LAWASTAFF;
  commPartner = AppConstants.COMMPARTNER;
  isLoggedIn: boolean = false;
  isApproved: boolean = false;
  constructor(
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private authService: MsalService,
    private appMonitorService: AppMonitorService,
  ) {
  }

  ngOnInit(): void {
    this.auth.currentUserStatus.subscribe((status: boolean) => {
      const loginStatus = localStorage.getItem(AppConstants.LOGINSTATUS);

      if (loginStatus) {
        this.isLoggedIn = loginStatus == AppConstants.TRUE ? true : false;
      } else {
        this.isLoggedIn = status;
      }

      const user = localStorage.getItem(AppConstants.USER);

      if (user) {
        this.userRole = JSON.parse(user).role;
      };


    });
    this.isApprovedEmployer();
    this.cdr.detectChanges();
  }


  onLoginClicked(): void {
    this.loginClicked.emit(); // Emit the event when the button is clicked
  }
  toggleSubmenu() {
    this.isSubmenuVisible = !this.isSubmenuVisible;
  }
  isApprovedEmployer() {
      let user = this.profileService.getUserClaims();
      if (!user || user == null) return;
      const isEmployer = user?.role === AppConstants.EMPLOYER;
      const isStaff = user?.role === AppConstants.LAWASTAFF;
      const isCBO = user?.role === AppConstants.COMMPARTNER;

      if (isCBO) return;
      if (isStaff) this.isApproved = true
      else if (isEmployer && user.isApproved) this.isApproved = true
  }
  private invalidUser(status: any) {
    localStorage.setItem('userStatus', status);
    this.logout();
  }
  logout() {
    this.appMonitorService.clearUserContext();
    localStorage.removeItem('Access-Token-Azure-B2C');
    localStorage.removeItem(AppConstants.USER);
    localStorage.setItem(AppConstants.LOGINSTATUS, AppConstants.FALSE);
    this.auth.loggedIn.next(false);
    this.authService.logout();
  }
}
