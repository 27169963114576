<!-- flex-md-row -->
<!-- <div class="container-fluid p-0"> -->
<div class="d-flex bg-color-dark px-2 px-sm-5">
  <div class="p-2 me-auto">
    <a href="{{CommonContent && CommonContent.logoLink}}">
      <img class="top-logo" *ngIf="!CommonContent" src="../../../../assets/img/los-angeles-logo.jpg" />
      <img class="top-logo" *ngIf="CommonContent && CommonContent.logo" [src]="basePath + CommonContent.logo.url" alt="Logo" />
    </a>
  </div>
  <div class="p-2 center">
    <a href="{{CommonContent && CommonContent.menu1Link}}" class="nav-custom-link"
      ><span class="badge bg-white text-dark">99+</span>
      <span class="hideOnMobile"> {{(CommonContent && CommonContent.menu1) ?? "City Services"}}</span></a
    >
  </div>
  <div class="p-2 center">
    <a href="{{CommonContent && CommonContent.menu2Link}}" class="nav-custom-link"
      ><img
        class="text-white"
        src="../../../../assets/icons/city-directory.svg"
      />
      <span class="hideOnMobile"> {{(CommonContent && CommonContent.menu2) ?? "City Directory"}} </span>
    </a>
  </div>
  <div class="p-2 center">
    <div class="dropdown">
      <button
        class="btn bg-color-dark dropdown-toggle"
        type="button"
        id="dropdownMenu2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="../../../../assets/icons/world.svg" /> EN
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
        <li>
          <button class="dropdown-item" type="button">English - EN</button>
        </li>
        <li>
          <button class="dropdown-item" type="button">Spanish - ES</button>
        </li>
        <li>
          <button class="dropdown-item" type="button">Chinese - ZH</button>
        </li>
        <li>
          <button class="dropdown-item" type="button">Arabic - AR</button>
        </li>
        <li>
          <button class="dropdown-item" type="button">French - FR</button>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- </div> -->
<div class="px-2 px-sm-5">
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid">
      <a routerLink="/" class="navbar-brand">
        <!-- <img
      class="logo"
      src="../../../../assets/img/lawa-logo-2.png"
      alt="Logo"
    /> -->
        <img class="logo" *ngIf="!CommonContent" src="../../../../assets/img/lawa-logo-2.png" />
        <img class="logo" *ngIf="CommonContent && CommonContent.navbarLogo" [src]="basePath + CommonContent.navbarLogo.url" alt="Logo" />
      </a>
      <button class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo01"
              aria-controls="navbarTogglerDemo01"
              aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul class="navbar-nav ms-auto me-5">
          <ng-container *ngIf="isLoggedIn && userRole === jobSeeker">
            <li class="nav-item">
              <a routerLink="/dashboard/jobseeker" class="nav-link p-4">My Dashboard</a>
            </li>
            <li class="nav-item">
              <a routerLink="/about-us" class="nav-link p-4">About</a>
            </li>
            <li class="nav-item">
              <a routerLink="/job-search" class="nav-link p-4">Job Search</a>
            </li>
            <li class="nav-item">
              <a routerLink="/resources" class="nav-link p-4">Resources</a>
            </li>
            <li class="nav-item">
              <a routerLink="/news-events" class="nav-link p-4">News/Events</a>
            </li>
            <li class="nav-item">
              <a routerLink="/contact" class="nav-link p-4">Contact</a>
            </li>
          </ng-container>

          <ng-container  *ngIf="isLoggedIn && userRole === employer">
            <li class="nav-item">
              <a routerLink="/dashboard/employer" class="nav-link p-4">My Dashboard</a>
            </li>
            <li class="nav-item">
              <a routerLink="/job-postings" class="nav-link p-4">Job Postings</a>
            </li>
            <li class="nav-item">
              <a routerLink="/candidate-search" class="nav-link p-4">Candidate Search</a>
            </li>           
            <li class="nav-item">
              <a (click)="toggleSubmenu()" class="nav-link p-4" *ngIf="isLoggedIn && isApproved">Resources <img src="assets/icons/menu_dropdown.svg" alt="" class="img-dropdown"></a>
              <ul *ngIf="isSubmenuVisible" class="submenu">
                <li><a href="">Resources</a></li>
                <li><a routerLink="/worksource-center">Seeking workforce recruitment</a></li>
              </ul>
            </li>         
            <li class="nav-item">
              <a routerLink="/employer-services" class="nav-link p-4">Employer Services</a>
            </li>
            <li class="nav-item">
              <a routerLink="/training" class="nav-link p-4">Training/How to Use</a>
            </li>
          </ng-container>

          <ng-container *ngIf="isLoggedIn && userRole === lawaStaff">
            <li class="nav-item">
              <a routerLink="/dashboard" class="nav-link p-4">My Dashboard</a>
            </li>
            <li class="nav-item">
              <button mat-button [matMenuTriggerFor]="usersMenu" class="nav-link p-4">Users</button>
              <mat-menu #usersMenu="matMenu" yPosition="below">
                  <a mat-menu-item routerLink="/user-listing/employer/view">Employers</a>
                  <a mat-menu-item routerLink="/user-listing/jobseeker/view">Job Seekers</a>
                  <a mat-menu-item routerLink="user-listing/cbo/view">Community Partners</a>
                  <a mat-menu-item routerLink="user-listing/lawastaff/view">LAWA Staffs</a>
              </mat-menu> 
            </li>
          </ng-container>

          <ng-container *ngIf="!isLoggedIn">
            <li class="nav-item">
              <a routerLink="/about-us" class="nav-link p-4">About</a>
            </li>
            <li class="nav-item">
              <a routerLink="/job-search" class="nav-link p-4">Job Search</a>
            </li>
            <!-- <li *ngIf="isLoggedIn && isApproved" class="nav-item">
              <a routerLink="/resources" class="nav-link p-4">Resources</a>
            </li> -->
            <li class="nav-item">
              <a routerLink="/news-events" class="nav-link p-4">News/Events</a>
            </li>
            <li class="nav-item">
              <a routerLink="/contact" class="nav-link p-4">Contact</a>
            </li>
          </ng-container>
        </ul>
        <app-user-login (loginClicked)="onLoginClicked()"></app-user-login>
      </div>


    </div>
  </nav>
</div>

<!-- <div class="d-flex flex-md-row flex-column px-5">
        <div class="p-4 ps-2 me-auto ">
            <a routerLink="/" class="logo-link">
                <img class="logo" src="../../../../assets/img/lawa-logo-2.png" alt="Logo" />
            </a>
        </div>
        <div class="p-4 center">
            <a routerLink="/about-us" class="nav2-custom-link"> About</a>
        </div>
        <div class="p-4 center">
            <a href="#" class="nav2-custom-link"> Job Search</a>
        </div>
        <div class="p-4 center">
            <a href="#" class="nav2-custom-link"> Resources</a>
        </div>
        <div class="p-4 center">
            <a href="#" class="nav2-custom-link"> News/Events</a>
        </div>
        <div class="p-4 center me-5">
            <a routerLink="/contact-us" class="nav2-custom-link"> Contact</a>
        </div>

        <div class="p-4 pe-2 center">
            <button class="btn btn-lg btnLogin" [routerLink]="['/login']">Login / Register</button>
        </div>
    </div> -->
