import { Component } from '@angular/core';

@Component({
  selector: 'app-worksource-center',
  templateUrl: './worksource-center.component.html',
  styleUrl: './worksource-center.component.css'
})
export class WorksourceCenterComponent {

}
